import React from 'react';
import SocialMenu from '../molecules/social-menu';

const Footer = () => (
  <footer style={{

  }}>
    <SocialMenu />
    <p>diego@diegoborgs.com.br<br />+55 31 98688-3889</p>
  </footer>
);

export default Footer;